<!--
 * @Author: Chen-
 * @Description:废弃-加油订单
 * @Date: 2020-11-25 17:06:11
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-04 18:02:02
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/Order/comeOn_Order.vue
-->
<template>
  <div class="content">
    <van-tabs v-model="active" swipeable @change="tabOrder">
      <van-tab v-for="tabItem in orderTitleList" :key="tabItem" :title="tabItem">
        <van-list
          v-model="loading"
          :finished="finished"
          :error.sync="error"
          :immediate-check="false"
          offset="300"
          error-text="请求失败，点击重新加载"
          finished-text=""
          @load="onLoad"
        >
          <div v-if="isData" class="noDataWrap">
            <img
              class="noDataImg"
              src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
              alt=""
            >
            <p class="noDataText">您还没有相关订单</p>
          </div>
          <ul v-else class="order_ul">
            <li v-for="(item,index) in orderList" :key="index" class="orderList">
              <div class="or_cen">
                <div class="or_li">{{ item.titleRe }}</div>
                <div class="or_li">
                  <!--                                5代付款11已支付20已完成25交易取消-->
                  <p v-if="item.status === 11 || item.status === 20" style="color: #E33F44">{{ item.statusText }}</p>
                  <p v-if="item.status === 5" style="">{{ item.statusText }}</p>
                  <p v-if="item.status === 25" style="color: #999999">{{ item.statusText }}</p>
                </div>
              </div>
              <div class="or_cen2">
                <div class="or_li">数量：{{ item.number }}</div>
                <div class="or_li">油号/枪号：{{ item.name }}{{ item.gun_no }}</div>
              </div>
              <div class="or_cen3">
                <p>提交时间：{{ item.time }}</p>
                <p>支付金额:{{ item.price }}</p>
              </div>
            </li>
          </ul>
        </van-list>

      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vue from 'vue'
import { Col, List, Row, Search, Tab, Tabs, Toast } from 'vant'
import { getOrderListNew } from '@/services/comeOn.js'
// import mescroll from '@/component/fresh_scroll/mescroll'
// import Clipboard from 'clipboard';
Vue.use(Search).use(Tab).use(Tabs).use(Col).use(Row).use(Toast).use(List)
export default {
  components: {

  },
  data() {
    return {
      active: 0,
      isData: false,
      page: 1,
      orderTitleList: ['全部', '已支付', '退款'],
      orderList: [],
      loading: false,
      finished: false,
      error: false
    }
  },
  created() {

  },
  mounted() {
    this.getList(0)
    this.mid = window.localStorage.getItem('uid')
  },
  methods: {
    tabOrder(e) {
      this.getList(e)
    },
    getList(e) { // 加油 0全部 11已支付  20已完成 25交易取消
      if (e === 1) { // 已支付
        e = 11
      } else if (e === 2) { // 退款
        e = 25
      }
      let getOrderListNewData
      if (e === 25) {
        getOrderListNewData = {
          page: this.page,
          pageSize: 10,
          orderType: 901,
          mid: this.mid,
          status: e,
          payStatus: 10// 查询退款订单时 payStatus:10 ，status:25, 其他情况下不传该参数
        }
      } else {
        getOrderListNewData = {
          page: this.page,
          pageSize: 10,
          orderType: 901,
          mid: this.mid,
          status: e
        }
      }
      getOrderListNew(getOrderListNewData).then(res => {
        Toast.loading({
          message: '加载中...',
          forbidClick: true
        })
        if (Number(res.code) === 200) {
          if (res.data.length < 1 || !res.data) {
            this.isData = true
            this.finished = true
            return
          } else if (res.data.length < 10) {
            this.finished = true
            this.orderList = this.orderList.concat(res.data)
            return
          } else {
            this.isData = false
            this.orderList = this.orderList.concat(res.data)
          }
          this.loading = false
          this.refreshing = false
        } else {
          // this.orderList = [];
          Toast.fail(res.msg)
        }
      })
    },
    // 滚动加载时触发，list组件定义的方法
    onLoad() {
      this.page++
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  background-color: #f8f8f8;
  min-height: 100vh;
  text-align: left;

  .noDataWrap {
    text-align: center;
    margin-top: 150px;

    .noDataImg {
      width: 150px;
      height: 150px;
    }

    .noDataText {
      font-size: 14px;
      color: #ccc;
      margin-top: 20px;
    }
  }

  .order_ul {
    margin-top: 7px;
    padding: 10px;

    li {
      padding: 10px;
      background: #fff;
      margin-bottom: 10px;
      border-radius: 7px;

      .or_cen {
        display: flex;
        text-align: left;

        .or_li {
          color: #000000;
          font-size: 12px;
        }

        .or_li:nth-child(1) {
          font-size: 16px;
          color: #333333;
          font-weight: 500;
        }

        .or_li:nth-child(2) {
          margin-left: auto;
        }
      }

      .or_cen2 {
        margin-left: auto;
        margin-top: 13px;
        font-size: 12px;
        color: #999999;
        margin-bottom: 10px;

        .or_li:nth-child(1) {
          margin-bottom: 10px;
        }
      }

      .or_cen3 {
        font-size: 12px;
        color: #999999;
        display: flex;

        p:nth-child(2) {
          margin-left: auto;
          color: #333333;
          font-size: 13px;
        }
      }
    }
  }
}
</style>
